import { useStaticQuery, graphql } from 'gatsby'
import { ConductorJosefsQuery } from 'types/graphql-types'

export type ConductorJosefOptions = 'Red' | 'Blue'

const useJosef = (whichJosef?: ConductorJosefOptions) => {
  const { allFile } = useStaticQuery<ConductorJosefsQuery>(graphql`
    query ConductorJosefs {
      allFile(
        filter: {
          relativeDirectory: { eq: "conductor-josefs" }
          sourceInstanceName: { eq: "images" }
        }
      ) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 50, quality: 100) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  //!bad abstraction is bad
  const josefIndex = whichJosef
    ? allFile.nodes.findIndex(
        (josef) =>
          josef.childImageSharp?.fluid?.originalName === `${whichJosef}.png`
      )
    : 0

  const josefs: ConductorJosefOptions[] = []
  allFile.nodes.forEach((node) =>
    josefs.push(
      node.childImageSharp?.fluid?.originalName?.replace(
        '.png',
        ''
      ) as ConductorJosefOptions
    )
  )
  josefs.sort()

  return {
    josefFluidData: allFile.nodes[josefIndex].childImageSharp?.fluid,
    josefs,
  }
}

export default useJosef
