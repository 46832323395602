import React, { useContext } from 'react'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'
import color from 'color'
import { IconContext } from 'react-icons'
import { WiTrain } from 'react-icons/wi'
import { AiFillNotification } from 'react-icons/ai'
import Img from 'gatsby-image'
import useConductorJosef from './use-conductor-josef'
import { LayoutContext } from '../layout'

/*
  ? Why there is no loading/buffering/whatever handler/indicator:

  ? Checking if the audio is paused while it waits for more data seems
  ? inconsistent and weird. There is a 'canplaythrough' event (or something)
  ? that could be waited for to show the button, but excluding very slow connections,
  ? the audio hits the 'canplaythrough' state so fast the any loading indicator
  ? just flashes briefly on the screen. However, if no loading indicator is shown and
  ? the button is just hidden until it can play through, very slow connections
  ? will just see a blank screen for a few seconds.

  ? So, right now, I just show the button right away, and people with really slow
  ? connections might get interrupted audio if they click it right away. Oh well.
*/

const TrainButton = () => {
  const { playTrainHorn } = useContext(LayoutContext)
  const { josefFluidData } = useConductorJosef('Red')

  const sizes = `7vmin`

  return (
    <BigButton onClick={playTrainHorn}>
      <IconContext.Provider
        value={{
          style: { verticalAlign: 'middle' },
          className: 'train-icons',
        }}
      >
        <Megaphone />
        <ElChooChooRey>
          <Img
            draggable={false}
            fadeIn={false}
            //♫ graphql/Img types are not my friend ♫
            fluid={{ ...(josefFluidData as any), sizes }}
          />
        </ElChooChooRey>
        <Train />
      </IconContext.Provider>
    </BigButton>
  )
}

export default TrainButton

const Megaphone = styled(AiFillNotification)`
  position: absolute;
  top: 6vmin;
  left: 0;
  color: ${(p) => p.theme.palette.secondary.main};
`
const Train = styled(WiTrain)`
  transform: rotateX(30deg);
  position: absolute;
  top: 14.5vmin;
  left: 17vmin;
  font-size: 0.75em;
  color: ${(p) =>
    color(p.theme.palette.background.default)
      .darken(0.3)
      .hex()};
`

const ElChooChooRey = styled.div`
  width: 5.5%;
  position: absolute;
  top: 51%;
  left: 37%;
  transform: translate(-50%, -50%);
  animation: popIn 800ms linear;
  @keyframes popIn {
    0% {
      top: 60%;
    }
    40% {
      top: 60%;
    }
    100% {
      top: 51%;
    }
  }
`

const size = 85
const dropSize = size * 0.067
const fontRatio = 75 / 85
const perspective = 'perspective(30cm) rotateX(20deg)'

const BigButton = styled.button`
  position: relative;
  width: ${size}vmin;
  height: ${size}vmin;
  line-height: ${size}vmin;
  font-size: ${size * fontRatio}vmin;
  text-align: center;
  border-radius: 50%;
  border: none;
  padding-right: 0; /*-Fixes IOS issue-*/
  background-color: ${(p) =>
    color(p.theme.palette.background.default)
      .darken(0.3)
      .hex()};
  transform: ${perspective};
  box-shadow: 0 ${dropSize}vmin
    ${(p) =>
      color(p.theme.palette.background.default)
        .darken(0.5)
        .hex()};

  background-image: ${(p) =>
    `linear-gradient(to bottom, ${color(p.theme.palette.background.default)
      .darken(0.5)
      .hex()}, ${color(p.theme.palette.background.default)
      .darken(0.3)
      .hex()})`};
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  :focus {
    outline: none !important;
  }
  :active {
    /* the Y translate is just eyeballed to accommodate 3d effect */
    transform: ${`translateY(${dropSize + 1.35}vmin)`} ${perspective};
    box-shadow: 0 0 ${(p) => p.theme.palette.secondary.dark};
  }
  /* adjusting for mobile bottom nav in landscape */
  @media screen and (orientation: landscape) {
    ${isMobile &&
      `width: ${size - 10}vmin;
  height: ${size - 10}vmin;
  line-height: ${size - 10}vmin;
  font-size: ${(size - 10) * fontRatio}vmin;
  `}
  }
`

// @keyframes growIn {
//   0% {
//     opacity: 1;
//     /* the Y translate is just eyeballed to accommodate 3d effect */
//     transform: ${`translateY(${dropSize + 1.35}vmin)`} ${perspective};
//     box-shadow: 0 0
//       ${(p) =>
//         color(p.theme.palette.background.default)
//           .darken(0.5)
//           .hex()};
//   }
//   100% {
//     transform: ${perspective};
//     box-shadow: 0 ${dropSize}vmin
//       ${(p) =>
//         color(p.theme.palette.background.default)
//           .darken(0.5)
//           .hex()};
//   }
// }
