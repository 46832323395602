import React, { useEffect, useContext } from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'
import { PageProps } from 'gatsby'
import { LayoutContext } from 'src/features/layout'
import TrainHornButton from 'src/features/train-horn-button'
import SEO from 'src/common/seo'
import useMotionNavOffsetStyle from 'src/common/hooks/use-motion-nav-offset-style'

const TrainHorn = ({ location }: PageProps) => {
  const props = useMotionNavOffsetStyle(
    { alignment: 'center' },
    { dodge: 'none' }
  )
  const { setLocation } = useContext(LayoutContext)

  useEffect(() => {
    setLocation(location)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <SEO title="Train Horn" />
      <Container {...props}>
        <TrainHornButton />
      </Container>
    </>
  )
}

export default TrainHorn

const Container = styled(motion.div)`
  position: fixed;
  /* top percentage eyeballed because of 3d perspective */
  top: ${isMobile ? 'calc(50% - (56px / 2))' : '43%'};
  left: 50%;
  transform: translate(-50%, -50%);
`
